@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .active {
    @apply outline-offset-2 focus:outline focus:outline-white/80;
  }

  .glass {
    @apply border border-transparent bg-black/10 backdrop-blur-md dark:bg-white/10;
  }

  .button {
    @apply glass active rounded-md p-1 text-center text-black dark:text-white;

    &.green {
      @apply bg-green-700/50;
    }

    &.red {
      @apply bg-red-800/50;
    }

    &:not(.action) {
      @apply py-2;
    }
  }

  .skeleton {
    @apply rounded bg-gray-500;

    &:not(.reduce) {
      @apply animate-pulse bg-gray-400;
    }
  }

  a {
    @apply active rounded;
  }

  input,
  textarea {
    @apply glass active m-0;
  }
}
